import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify, AuthModeStrategyType } from 'aws-amplify';

import App from './App';
import reportWebVitals from './reportWebVitals';
import awsExports from './aws-exports';

Amplify.configure({
  ...awsExports,
  // see https://aws.amazon.com/blogs/mobile/aws-amplify-allows-you-to-mix-and-match-authorization-modes-in-datastore/
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
